<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-account-group"
          inline
          class="px-5 py-4 ml-0"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">Perfis de Usuários</div>
          </template>

          <v-btn
            id="btn-novo-usuario"
            v-if="tipoAutenticacao === 'database' && usuarioAdmin"
            color="success"
            dark
            elevation="1"
            class="mt-5"
            style="float: left"
            relative
            text
            medium
            @click="$router.push('edit')"
          >
            <v-icon
              left
              size="30px"
            >
              mdi-plus-circle
            </v-icon>
            Novo Perfil de Usuário
          </v-btn>

          <v-text-field
            id="perfis-usuarios-historico-search"
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-auto mt-4 break-search bs-perfil"
            label="Procurar"
            hide-details
            single-line
            style="max-width: 250px"
          />

          <v-divider class="mt-10" />

          <v-data-table
            class="perfil-usuario-table"
            :headers="headers"
            :items="perfis"
            :search.sync="search"
            :headerProps="{
              sortByText: 'Ordenar Por'
            }"
            :footer-props="{
              itemsPerPageText: 'Itens por Página'
            }"
            :mobile-breakpoint="800"
            :loading="loading"
            :hide-default-header="loading"
            :hide-default-footer="loading"
            no-results-text="Nenhum registro correspondente encontrado"
          >
            <template v-slot:[`item.active`]="{ item }">
              <status :status="item.active" />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                id="action-edit-usuario"
                v-if="tipoAutenticacao === 'database' && usuarioAdmin"
                :class="!canEditItem(item) ? 'cursor-block' : ''"
                :disabled="!canEditItem(item)"
                color="gray"
                min-width="0"
                class="px-1 ml-n1"
                fab
                icon
                x-small
                @click="$router.push(`edit/${item.id}`)"
              >
                <v-icon small> mdi-pencil </v-icon>
              </v-btn>
              <v-menu
                offset-y
                v-if="canEditItem(item)"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    id="action-more-usuario"
                    color="white"
                    class="ml-1"
                    height="22px"
                    width="22px"
                    fab
                    x-small
                    elevation="1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small> mdi mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="changeStatus(item.id, item.active)">
                    <v-list-item-title>
                      <v-icon
                        small
                        v-if="item.active === 'Ativo'"
                      >
                        mdi-account-cancel
                      </v-icon>
                      <v-icon v-else> mdi-account </v-icon>
                      <span v-if="item.active === 'Ativo'"> Inativar </span>
                      <span v-else> Ativar </span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthService from '@/services/AuthService';
import PerfisUsuariosService from '@/services/PerfisUsuariosService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';

export default {
  name: 'PerfisUsuariosListar',

  mixins: [refreshDataMixins, profilePermissionsMixin],

  components: {
    DialogChangeStatus: () => import('@/components/general/DialogChangeStatus'),
    Status: () => import('@/components/general/Status')
  },

  data() {
    return {
      usuarioAdmin: false,
      tipoAutenticacao: null,
      search: '',
      loading: false,
      perfis: [],
      headers: [
        {
          text: '#',
          value: 'id'
        },
        {
          text: 'Titulo',
          value: 'title'
        },
        {
          text: 'Descrição',
          value: 'description'
        },
        {
          text: 'Criado por',
          value: 'user'
        },
        {
          text: 'Ativo',
          value: 'active'
        },
        {
          sortable: false,
          text: 'Ações',
          value: 'actions',
          class: 'pl-4'
        }
      ]
    };
  },

  mounted() {
    this.refreshData(this.getPerfisUsuarios);
    this.getUsuarioAdmin();
    this.getTipoAutenticacao();
  },

  computed: {
    usuario() {
      return this.$store.getters.getUser;
    }
  },

  methods: {
    getPerfisUsuarios() {
      this.perfis = [];
      this.loading = true;

      PerfisUsuariosService.getPerfisUsuarios()
        .then(({ data }) => {
          this.perfis = data.data;
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error('Erro ao recuperar os perfis de usuários.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    },

    async getUsuarioAdmin() {
      this.usuarioAdmin = await AuthService.userIsAdmin();
    },

    async getTipoAutenticacao() {
      this.tipoAutenticacao = await AuthService.getTipoAutenticacao();
    },

    canEditItem(item) {
      return (
        this.tipoAutenticacao === 'database' &&
        this.usuarioAdmin &&
        item.title !== 'Administrador'
      );
    },

    changeStatus(id, status) {
      const data = {
        id: id,
        status: status === 'Ativo' ? false : true
      };

      this.loading = true;

      PerfisUsuariosService.changeStatus(data)
        .then(() => {
          this.$toast.success(
            'Status do Perfil de Usuário salvo com sucesso.',
            '',
            {
              position: 'topRight'
            }
          );
        })
        .catch((err) => {
          console.error(err);
          this.$toast.error(
            'Erro ao salvar o satus do perfil de usuário.',
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => this.getPerfisUsuarios());
    }
  }
};
</script>

<style>
@media screen and (min-width: 800px) {
  .perfil-usuario-table table tr td:nth-child(2) {
    max-width: 200px;
  }

  .perfil-usuario-table table tr td:nth-child(5),
  .perfil-usuario-table table tr td:nth-child(6) {
    white-space: nowrap !important;
  }
}

@media screen and (max-width: 480px) {
  .bs-perfil {
    width: 215.36px !important;
  }
}
</style>
