var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-account-group","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v("Perfis de Usuários")])]},proxy:true}])},[(_vm.tipoAutenticacao === 'database' && _vm.usuarioAdmin)?_c('v-btn',{staticClass:"mt-5",staticStyle:{"float":"left"},attrs:{"id":"btn-novo-usuario","color":"success","dark":"","elevation":"1","relative":"","text":"","medium":""},on:{"click":function($event){return _vm.$router.push('edit')}}},[_c('v-icon',{attrs:{"left":"","size":"30px"}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Novo Perfil de Usuário ")],1):_vm._e(),_c('v-text-field',{staticClass:"ml-auto mt-4 break-search bs-perfil",staticStyle:{"max-width":"250px"},attrs:{"id":"perfis-usuarios-historico-search","append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{staticClass:"perfil-usuario-table",attrs:{"headers":_vm.headers,"items":_vm.perfis,"search":_vm.search,"headerProps":{
            sortByText: 'Ordenar Por'
          },"footer-props":{
            itemsPerPageText: 'Itens por Página'
          },"mobile-breakpoint":800,"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"no-results-text":"Nenhum registro correspondente encontrado"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
          var item = ref.item;
return [_c('status',{attrs:{"status":item.active}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(_vm.tipoAutenticacao === 'database' && _vm.usuarioAdmin)?_c('v-btn',{staticClass:"px-1 ml-n1",class:!_vm.canEditItem(item) ? 'cursor-block' : '',attrs:{"id":"action-edit-usuario","disabled":!_vm.canEditItem(item),"color":"gray","min-width":"0","fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.$router.push(("edit/" + (item.id)))}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1):_vm._e(),(_vm.canEditItem(item))?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"id":"action-more-usuario","color":"white","height":"22px","width":"22px","fab":"","x-small":"","elevation":"1"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.changeStatus(item.id, item.active)}}},[_c('v-list-item-title',[(item.active === 'Ativo')?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-account-cancel ")]):_c('v-icon',[_vm._v(" mdi-account ")]),(item.active === 'Ativo')?_c('span',[_vm._v(" Inativar ")]):_c('span',[_vm._v(" Ativar ")])],1)],1)],1)],1):_vm._e()]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }